<template>
  <v-container class="payment-completed" v-if="!loading">
    <v-row no-gutters class="mt-7">
      <v-col cols="12" class="order-info">
        <v-card class="text-left default--text h-100" elevation="0">
          <div class="d-flex align-center flex-row flex-wrap">
            <h1
              class="font-weight-bold default--text"
              v-if="order && paymentFailed"
            >
              {{ $t("paymentCompleted.failed", [order.orderId]) }}
            </h1>
          </div>
          <v-card-text v-if="order && paymentFailed" class="px-0 mt-3">
            <div
              class="description"
              v-html="$t('paymentCompleted.failedDesc1')"
            />
            <div
              class="description mt-2"
              v-html="$t('paymentCompleted.failedDesc2')"
            />
          </v-card-text>

          <v-card-text v-if="order && !paymentFailed">
            <h1
              class="font-weight-bold default--text mb-4"
              v-html="$t('paymentCompleted.success', [order.orderId])"
            />
            <i18n
              path="paymentCompleted.pending"
              tag="div"
              class="description mt-2"
              v-if="order.orderStatusId === 1"
            >
              <template v-slot:link>
                <router-link
                  class="text-decoration-none"
                  :to="{
                    name: 'Order',
                    params: { orderId: order.orderId }
                  }"
                >
                  <span class="font-weight-bold default--text">
                    {{ $t("paymentCompleted.ordersLink") }}</span
                  >
                </router-link>
              </template>
            </i18n>
            <div
              class="description mt-2"
              v-html="$t('paymentCompleted.successDesc')"
            />

            <v-container class="list-order-details description px-0">
              <!-- PICKUP -->
              <div v-if="!order.isPayable && !order.homeDelivery && !easyDrive">
                <span v-html="$t('paymentCompleted.detail.pickup.pre')" />
                <ul>
                  <li
                    v-html="$t('paymentCompleted.detail.date', [detailDate])"
                  />
                  <li v-html="detailTimeslot" />
                  <li
                    v-html="
                      $t('paymentCompleted.detail.pickup.last', [
                        order.shippingAddress.addressName
                      ])
                    "
                  />
                </ul>
              </div>

              <!-- DRIVE -->
              <div v-if="!order.isPayable && !order.homeDelivery && easyDrive">
                <span v-html="$t('paymentCompleted.detail.drive.pre')" />
                <ul>
                  <li
                    v-html="$t('paymentCompleted.detail.date', [detailDate])"
                  />
                  <li v-html="detailTimeslot" />
                  <li
                    v-html="
                      $t('paymentCompleted.detail.drive.last', [
                        order.shippingAddress.addressName
                      ])
                    "
                  />
                </ul>
              </div>

              <!-- HOME -->
              <div v-if="!order.isPayable && order.homeDelivery">
                <span v-html="$t('paymentCompleted.detail.home.pre')" />
                <ul>
                  <li
                    v-html="$t('paymentCompleted.detail.date', [detailDate])"
                  />
                  <li v-html="detailTimeslot" />
                  <li
                    v-html="
                      $t('paymentCompleted.detail.home.last', [
                        order.shippingAddress.address1,
                        order.shippingAddress.city
                      ])
                    "
                  />
                </ul>
              </div>
            </v-container>

            <v-container class="reminder" v-if="!order.isPayable">
              <span v-html="$t('paymentCompleted.reminder.pre')" />
              <ul>
                <li>
                  <template>
                    <i18n path="paymentCompleted.reminder.1" tag="span">
                      <template v-slot:link>
                        <router-link to="/dashboard">
                          <span class="font-weight-bold default--text">
                            {{
                              $t("paymentCompleted.reminder.dashboard")
                            }}</span
                          >
                        </router-link>
                      </template>
                    </i18n>
                  </template>
                </li>
                <li>
                  <template>
                    <i18n path="paymentCompleted.reminder.2" tag="span">
                      <template v-slot:link>
                        <router-link to="/dashboard">
                          <span class="font-weight-bold default--text">
                            {{
                              $t("paymentCompleted.reminder.dashboard")
                            }}</span
                          >
                        </router-link>
                      </template>
                    </i18n>
                  </template>
                </li>
                <li>
                  <template>
                    <i18n path="paymentCompleted.reminder.3" tag="span">
                      <template v-slot:link>
                        <router-link to="/profile/ticket">
                          <span class="font-weight-bold default--text">
                            {{
                              $t("paymentCompleted.reminder.customerService")
                            }}</span
                          >
                        </router-link>
                      </template>
                    </i18n>
                  </template>
                </li>
              </ul>
            </v-container>
          </v-card-text>

          <v-card-text v-if="!order">
            <h1
              class="font-weight-bold default--text mb-4"
              v-html="$t('paymentCompleted.successNoOrder')"
            />
          </v-card-text>

          <TigrosPaymentTypeList
            v-if="showPayment"
            mode="order"
            :order-id="order.orderId"
            :options="paymentTypeListOptions"
          />

          <v-card-actions class="">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="12" sm="auto" class="px-0 px-sm-3 mb-4 mb-sm-0">
                <v-btn
                  x-large
                  depressed
                  class="text-none"
                  :color="order ? 'secondary' : 'primary'"
                  :block="$vuetify.breakpoint.xs"
                  @click="goHome"
                  >{{ $t("paymentCompleted.goToHome") }}</v-btn
                >
              </v-col>
              <v-col
                cols="12"
                sm="auto"
                v-if="paymentFailed && order.isPayable"
                class="mb-4 mb-sm-0"
              >
                <v-btn
                  v-if="!showPayment"
                  x-large
                  depressed
                  class="text-none"
                  color="primary"
                  :block="$vuetify.breakpoint.xs"
                  @click="toggleShowPayment"
                >
                  {{ $t("paymentCompleted.retry") }}
                </v-btn>
              </v-col>

              <v-col
                v-if="order && !paymentFailed"
                cols="12"
                sm="auto"
                class="px-0 px-sm-3"
              >
                <v-btn
                  v-bind:to="'/profile/orders/' + order.orderId"
                  x-large
                  depressed
                  class="text-none"
                  :color="order.isPayable ? 'secondary' : 'primary'"
                  :block="$vuetify.breakpoint.xs"
                >
                  {{ $t("paymentCompleted.goToDetail") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.payment-completed {
  h1 {
    font-size: 38px;
    line-height: 1;
  }
  .description,
  .reminder {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .list-order-details {
    margin-top: 40px;
    ul {
      margin: 14px 0px;
      padding-left: 40px !important;
    }
  }
}
</style>
<script>
import TigrosPaymentTypeList from "@/components/payment/TigrosPaymentTypeList.vue";

import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: {
    TigrosPaymentTypeList
  },
  data() {
    return {
      loading: true,
      order: null,
      showPayment: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        prevButton: {
          type: "link-button",
          label: "payment.backToCheckout",
          class: "text-none d-none",
          options: {
            icon: "$prev",
            iconClass: "primary white--text rounded-lg mr-3",
            textClass: "primary--text text-caption font-weight-bold"
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    paymentFailed() {
      return this.order?.orderStatusId == 2 || this.order?.orderStatusId == 11;
    },
    detailDate() {
      return this.$dayjs(this.order.timeslot.date).format("DD MMMM");
    },
    detailTimeslot() {
      return `dalle ore ${this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")} alle ore
        ${this.$dayjs(this.order.timeslot.endTime, [
          "h:mm:ss A",
          "H:mm"
        ]).format("HH:mm")}`;
    },
    easyDrive() {
      return (
        this.order.warehouse.params != undefined &&
        this.order.warehouse.params.EASYDRIVE === "1"
      );
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics"
    }),
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    async fetchOrder(orderId) {
      let _this = this;
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!this.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
      _this.loading = false;
    },
    goHome() {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    this.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
